<template>
  <div class="header">
    <div class="clock">
      <div class="clock-content">
        <van-icon name="arrow-left" size="30px" color="#fff" @click="onClickLeft"/>
        <div class="clock-content-right">
          <img class="clock-content-right-img" src="./img/miaosha.png" alt="">
          <div class="clock-content-right-shuo">
            <span class="clock-content-right-shuo-item">地址1折</span>
            <span class="clock-content-right-shuo-item">|</span>
            <span class="clock-content-right-shuo-item">5折抢购</span>
            <span class="clock-content-right-shuo-item">|</span>
            <span class="clock-content-right-shuo-item">买贵必赔</span>
          </div>
        </div>
      </div>
      
      <div class="clock-time">
        <div class="clock-time-timeout" v-if="activeNum > 0">
          <span style="font-size: 14px;">{{ activeName }}</span>
          <van-count-down :time="time" @finish="finish">
            <template #default="timeData">
              <span class="block">{{ timeData.hours }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.minutes }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.seconds }}</span>
            </template>
          </van-count-down>
        </div>
        <span style="color: #F52555;" v-if="activeNum > 1">|</span>

        <div class="clock-time-start" v-if="activeNum > 1">
          <p style="font-size: 14px;">{{ activeName2 }}</p>
          <div class="clock-time-start-kaishi">
            <img class="clock-time-start-kaishi-dian" src="./img/dian.png" alt="">
            <span style="font-size: 12px;">即将开始</span>
          </div>
        </div>
        <p class="clock-time-active" v-if="activeNum == 0">暂无活动，敬请期待</p>
      </div>

      <img class="clock-img" src="./img/clock.png" alt="">
    </div>

    <div class="container">
      <div class="container-kong" v-if="shopList.length == 0">
        <img class="container-kong-img" src="./img/kong.png" alt="">
        <p>暂无商品</p>
      </div>

      <div class="shopList" v-for="x in shopList" :key="x.spuId">
        <img class="shopList-img" :src="x.picUrl" alt="">
        <div class="shopList-right">
          <p class="shopList-right-name">{{ x.spuName }}</p>
          <div class="shopList-right-tags">
            <van-tag color="#F52555" plain>{{ x.freightName }}</van-tag>
          </div>
          <div class="shopList-right-price">
            <span style="font-size: 14px; color: #F52555;">￥</span>
            <span style="font-size: 20px; color: #F52555;">{{ x.discountPrice }}</span>
            <div class="shopList-right-price-discount">
              <div class="shopList-right-price-discount-text">{{ x.discount * 10 }}折</div>
            </div>
            <div class="shopList-right-price-discount-selling">
              <span>日常价</span>
              <span>￥</span>
              <span>{{ x.salesPrice }}</span>
            </div>
          </div>

          <div class="shopList-right-scheddule" v-if="x.qiangType">
            <div class="shopList-right-scheddule-left">
              <span style="flex: 1;">
                <van-progress 
                  :percentage="x.jindu" 
                  stroke-width="8" 
                  pivot-text=""
                  track-color="#FFCBCB"
                  color="linear-gradient(to right, #FE5C5C, #F52555)"/>
              </span>
              <div class="shopList-right-scheddule-left-text">
                <div style="transform: scale(.8);">已抢{{ x.jindu }}%</div>
              </div>
            </div>
            <div class="shopList-right-scheddule-right" @click="enterDetail(x.shopType, x.spuId)">
              <img class="shopList-right-scheddule-right-huo" src="./img/huo.png" alt="">
            </div>
          </div>

          <div class="shopList-right-scheddule" v-else>
            <div class="shopList-right-scheddule-left">
              <img class="shopList-right-scheddule-left-img" src="./img/kaishi.png" alt="">
              <span class="shopList-right-scheddule-left-zi">即将开始</span>
            </div>
            <div class="shopList-right-scheddule-weikaishi">
              <img class="shopList-right-scheddule-weikaishi-img" src="./img/weikaishi.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading :loading="loading" />
  </div>
</template>

<script>
import Loading from "./Loading"
import { getDiscountPage, searchActiveTime } from '@/api/Ge/port'
export default {
  components: {
    Loading
  },

  data () {
    return {
      time: 0,
      timeData: {
        hours: '',
        minutes: '',
        seconds: ''
      },
      formInline: {
        current: 1,
        size: 10,
        activeId: ''
      },
      shopList: [],
      activeName: '',
      activeName2: '',
      activeNum: 0,  // 当日活动数量
      loading: false
    }
  },

  mounted() {
    this.$store.commit("setSession", this.$route.query.session)
    let screenWidth = document.documentElement.getBoundingClientRect().width
    document.documentElement.style.fontSize = screenWidth / 10 + "px"
    this.formInline.activeId = this.$route.query.activeId
    this.searchActiveTime()
  },

  methods: {
    // 今日场次
    searchActiveTime () {
      this.loading = true
      searchActiveTime().then(res => {
        if (res.data.code === 0) {
          this.getPage(res.data.data[0])
          const actives = res.data.data
          let activeNum = actives.length
          this.activeNum = activeNum
          if (activeNum > 2) {
            this.activeName = actives[0].activeName
            this.activeName2 = actives[1].activeName
          } else if (activeNum > 1) {
            this.activeName = actives[0].activeName
            this.activeName2 = actives[1].activeName
          } else if (activeNum > 0) {
            this.activeName = actives[0].activeName
          }

          if (activeNum > 0) {
            // 当前时间 时分秒
            const date = new Date()
            const h = date.getHours()
            const m = date.getMinutes()
            const s = date.getSeconds()

            // 当前数据开始 时分秒
            const activeStartTime = actives[0].activeStartTime
            const h2 = activeStartTime.split(':')[0]
            const m2 = activeStartTime.split(':')[1]
            const s2 = activeStartTime.split(':')[2]

            // 当前数据结束 时分秒
            const activeEndTime = actives[0].activeEndTime
            const h3 = activeEndTime.split(':')[0]
            const m3 = activeEndTime.split(':')[1]
            const s3 = activeEndTime.split(':')[2]

            const r = Number(h * 3600) + Number(m * 60) + Number(s)
            const r2 = Number(h2 * 3600) + Number(m2 * 60) + Number(s2)
            const r3 = Number(h3 * 3600) + Number(m3 * 60) + Number(s3)

            if (r <= r2) {
              this.time = (r2 - r) * 1000
            } else {
              this.time = (r3 - r) * 1000
            }
          }
        } else {
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
      })
    },

    // 折扣商品列表
    getPage (x = {id: ''}) {
      getDiscountPage({ current: 1, size: 500, activeId: x.id }).then(res => {
        if (res.data.code === 0) {
          this.loading = false
          res.data.data.records.forEach(x => {
            x.discountPrice = x.discountPrice.toFixed(2)
            x.salesPrice = x.salesPrice.toFixed(2)
            x.jindu = (Number((x.salesQuantity / x.spuNumber)) * 100).toFixed(0)
            // 当前时间 时分秒
            const date = new Date()
            const h = date.getHours()
            const m = date.getMinutes()
            const s = date.getSeconds()

            // 当前数据 开始时分秒
            const activeStartTime = x.activeStartTime
            const h2 = activeStartTime.split(':')[0]
            const m2 = activeStartTime.split(':')[1]
            const s2 = activeStartTime.split(':')[2]

            const r = Number(h * 3600) + Number(m * 60) + Number(s)
            const r2 = Number(h2 * 3600) + Number(m2 * 60) + Number(s2)
            
            // true 开始 false 未开始
            if (r < r2) {
              x.qiangType = false
            } else {
              x.qiangType = true
            }
          })
          this.shopList = res.data.data.records
        } else {
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
      })
    },

    finish () {
      setTimeout(() => {
        this.searchActiveTime()
      }, 1500)
    },

    enterDetail (shopType, id) {
      if (shopType == '0') {
        this.$router.push({
          path: '/flMallCommodityDetail',
          query: {
            id,
            session: this.$route.query.session,
            ticketActive: '2'
          }
        })
      } else if (shopType == '1') {
        this.$router.push({
          path: '/milimallDetail',
          query: {
            id,
            session: this.$route.query.session,
            ticketActive: '2'
          }
        })
      } else if (shopType == '3') {
        this.$router.push({
          path: '/ydMallCommodityDetail',
          query: {
            id,
            session: this.$route.query.session,
            ticketActive: '2'
          }
        })
      }
    },

    onClickLeft () {
      if (!this.isShow) {
        this.isShow = true
      } else {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
        if (isIOS) {
          window.webkit.messageHandlers.close.postMessage("up")
        }
        if (isAndroid) {
          window.android.close()
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  height: 100%;
  background: #F6F6F6;
  display: flex;
  flex-direction: column;
}
.clock {
  height: 140px;
  background: linear-gradient(to right, #fc3338, #ff7a48);
  position: relative;
  padding-top: 10px;
  &-img {
    width: 96px;
    height: 109px;
    position: absolute;
    right: 25px;
    top: 5px;
    z-index: 1;
  }
  &-content {
    display: flex;
    align-items: center;
    &-right {
      &-img {
        width: 142px;
        height: 24px;
        vertical-align: bottom;
      }
      &-shuo {
        font-size: 14px;
        color: #fff;
        display: flex;
        &-item {
          margin-right: 5px;
        }
      }
    }
  }
  &-time {
    width: 96%;
    height: 50px;
    margin: auto;
    border-radius: 25px;
    background: #fff;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    position: relative;
    &-timeout {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &-start {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-kaishi {
        display: flex;
        align-items: center;
        &-dian {
          width: 11px;
          height: 12px;
        }
      }
    }
    &-active {
      font-size: 14px;
      margin: auto;
    }
  }
}
.colon {
  display: inline-block;
  margin: 0 4px;
  color: #ee0a24;
}
.block {
  display: inline-block;
  width: 22px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: linear-gradient(#FA9605, #FF5300);
  border-radius: 2px;
}
.container {
  flex: 1;
  flex-shrink: 0;
  background: #F6F6F6;
  transform: translateY(-10px);
  border-radius: 10px 10px 0 0;
  padding: 10px 10px 0 10px;
  overflow: auto;
  &-kong {
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-img {
      width: 120px;
    }
  }
}
.shopList {
  padding: 10px;
  display: flex;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  &-img {
    width: 100px;
    height: 100px;
    border-radius: 2px;
    margin-right: 10px;
  }
  &-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-name {
      font-size: 13px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    &-price {
      display: flex;
      align-items: center;
      &-discount {
        color: #fff;
        background: linear-gradient(to right, #FF5300, #FA9605);
        border-radius: 9px 9px 9px 0px;
        margin: 0 5px;
        &-text {
          transform: scale(.8);
        }
        &-selling {
          color: #bcbcbc;
          text-decoration: line-through;
          transform: scale(.8);
        }
      }
    }
    &-scheddule {
      display: flex;
      height: 30px;
      &-left {
        flex: 1;
        display: flex;
        align-items: center;
        border-radius: 15px 0 0 15px;
        background: linear-gradient(#FEF4E3, #FEE5BB);
        padding: 0 10px;
        &-text {
          color: #F52555;
        }
        &-img {
          width: 23px;
          height: 23px;
          margin-right: 5px;
        }
        &-zi {
          font-size: 12px;
          color: #F52555;
        }
      }
      &-right {
        width: 50px;
        background: linear-gradient(to right, #FE5C5C, #F52555);
        border-radius: 0 15px 15px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        &-huo {
          width: 26px;
          height: 18px;
        }
      }
      &-weikaishi {
        width: 50px;
        background: #999;
        border-radius: 0 15px 15px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        &-img {
          width: 39px;
        }
      }
    }
  }
}
</style>